<template>
  <div class="py-4 sm:py-4 max-w-screen-xl mx-auto">
    <PageEditor
      class="h-full"
      :page-link="pageLink"
      :is-admin="authStore.isAdmin"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth } from '~/stores/auth'

const route = useRoute()
const localeRoute = useLocaleRoute()
const host = useHost()
const authStore = useAuth()

const pageLink = computed(() => {
  return `${host}${localeRoute(route)?.path}`
})
</script>
